.editContactWindow {
  width: 650px;
  :global(.input) {
    &-group-prepend {
      > span {
        width: 92px;
      }
    }
  }
  .prefillFormGroup {
    max-width: 65%;
    margin-right: auto;
    margin-left: auto;
  }
}
