@import "config";

.pageActionsRow {
  display: flex;
  position: relative;
  flex: none;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 4.4em;
  margin: 0 auto $contentDefaultIndent / 2 auto;
  padding: $contentDefaultIndent $contentDefaultIndent * 2;
  /* stylelint-disable-next-line plugin/no-low-performance-animation-properties */
  transition: box-shadow 300ms ease-out, width 300ms ease-out;
  border-radius: $contentDefaultIndent;
  background-color: $uiWhiteColor;
  &.sticky {
    position: relative;
    position: sticky;
    z-index: 500;
    top: $contentDefaultIndent * 2;
    &[data-navbar=false] {
      top: $contentDefaultIndent * 2;
    }
  }
  &.stuck {
    width: calc(100% - #{($contentDefaultIndent * 4) - 2px});
    box-shadow: 0 0.5rem 4rem rgba($uiBlackColor, 0.11),
      0 10px 20px rgba($uiBlackColor, 0.05),
      0 2px 3px rgba($uiBlackColor, 0.06);
  }
  > div {
    &:first-child {
      margin-right: auto;
    }
    + div {
      flex: none;
      margin-left: $contentDefaultIndent;
    }
  }
}

.sibling {
  margin: $contentDefaultIndent / 2 0 0;
}
