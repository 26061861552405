@import "config";

.pageHeader {
  display: flex;
  align-items: center;
  height: $pageHeaderHeight;
  min-height: $pageHeaderHeight;
  font-weight: 500;
  .leftBlock {
    min-width: 0;
    margin-right: $contentDefaultIndent * 2;
    .subTitle {
      overflow: hidden;
      opacity: 0.5;
      color: $uiDarkColor;
      font-size: 0.8em;
      letter-spacing: 0.1em;
      text-overflow: ellipsis;
      text-transform: uppercase;
      white-space: nowrap;
    }
    .title {
      display: flex;
      align-items: center;
      overflow: hidden;
      font-size: 1.6em;
      line-height: 1.2;
      white-space: nowrap;
      > svg {
        flex: none;
      }
      > span {
        display: inline-flex;
        min-width: 0;
        .separator {
          margin: 0 0.3em;
        }
        a {
          color: $uiDarkColor;
        }
        a,
        span[disabled] {
          min-width: 3em;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
  }
  .rightBlock {
    flex-shrink: 0;
    margin-left: auto;
    text-align: right;
  }
}
