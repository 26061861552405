@import "config";

.contactsTable {
  .badge {
    display: inline-block;
    border: 1px solid transparent;
    color: $uiWhiteColor;
    &:global(.vendor) {
      background-color: darken($uiHighlightColor, 20%);
    }
    &:global(.customer) {
      background-color: darken($uiPositiveColor, 10%);
    }
  }
}
